import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import backArrowIcon from "../../img/back-arrow.png";
import printIcon from "../../img/icon-print.png";
import { BACKEND_URL } from "../../utils/constant";
import * as XLSX from 'xlsx';

const ReceivableReportByYear = () => {
    const handleExportClick = (fileName) => {
        const table = document.querySelector("#xlsx-export").cloneNode(true);
        const workbook = XLSX.utils.table_to_book(table, { display: false });
        XLSX.writeFile(workbook, fileName)
    };
    const formatMessage = useFormatMessage();
    const [reportYear, setReportYear] = useState(2023);
    const [data, setData] = useState([]);

    useEffect(() => {
        let url = BACKEND_URL + `/reports/receivablereportyearly?year=${reportYear}`;
        axios
            .get(`${url}`, {
                withCredentials: true,
            })
            .then((response) => {
                console.log("Response - ", response.data);
                setData(response.data.data);
            })
            .catch((e) => {
                console.log(e.response);
            });
    }, [reportYear]);

    const calculateTotal = (data, key) => {
        let total = 0;

        for (const index of data) {
            total += index[key];
        }
        return total.toLocaleString("en-US");
    }

    return (
        <div className="sub-page-layout-1">
            <Link className="back-to-page" to="/dashboard/report">
                <img src={backArrowIcon} />
                {formatMessage("common.back")}
            </Link>

            <div className="flex justify-between text-3xl">
                <h1>{formatMessage("common.receivableReportByYear")}</h1>
                <div className="flex">
                    <input
                        required
                        name="report_year"
                        value={reportYear}
                        onChange={e => setReportYear(e.target.value)}
                        className="x-input mr-3"
                        type="number"
                    />
                    <button className="header-img-button" onClick={() => {
                        handleExportClick(`Receivable_Report_By_Year_${reportYear}.xlsx`)
                    }}>
                        <img src={printIcon} />
                    </button>
                </div>

            </div>

            <div className="table-container">
                <table className="x-table" id="xlsx-export">
                    <thead>
                        <tr style={{ "position": "absolute", "width": "0", "height": "0", "padding": "0", "border": "0" }}>
                            <th className="hidden" colSpan={3}></th>
                            <th className="hidden">{formatMessage("common.year")}</th>
                            <th className="hidden">{reportYear}</th>
                        </tr>
                        <tr style={{ "position": "absolute", "width": "0", "height": "0", "padding": "0", "border": "0" }}>
                            <th className="hidden" colSpan={5}></th>
                        </tr>
                        <tr>
                            <th>{formatMessage("common.no")}</th>
                            <th>{formatMessage("common.month")}</th>
                            <th>{formatMessage("common.totalAmount")}</th>
                            <th>{formatMessage("common.paidAmount")}</th>
                            <th>{formatMessage("common.receivableAmount")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((item, i) => {
                            return (
                                <tr key={i} className="hover-effect">
                                    <td>{i + 1}</td>
                                    <td>{item["Month"]}</td>
                                    <td>{parseInt(item["Total Amount"]).toLocaleString("en-US")}</td>
                                    <td>{parseInt(item["Paid Amount"]).toLocaleString("en-US")}</td>
                                    <td>{parseInt(item["Receivable Amount"]).toLocaleString("en-US")}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            <td>{formatMessage("common.totalAmount")}</td>
                            <td>{data && calculateTotal(data, "Total Amount")}</td>
                            <td>{data && calculateTotal(data, "Paid Amount")}</td>
                            <td>{data && calculateTotal(data, "Receivable Amount")}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    );
}

export default ReceivableReportByYear;

import * as XLSX from "xlsx";

const useReport = () => {
  const generateAndDownloadExcel = (data, filename) => {
    // Create a new worksheet from JSON data
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Create binary excel file from the workbook
    const excelFile = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Create a Blob from the excel file for download
    const blob = new Blob([excelFile], { type: "application/octet-stream" });

    // Create a download link and click it programmatically
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${filename}.xlsx`); // File name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return { generateAndDownloadExcel };
};

export default useReport;

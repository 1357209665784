import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import backArrowIcon from "../../img/back-arrow.png";
import searchIcon from "../../img/icon-search-white.png";
import printIcon from "../../img/icon-print.png";
import { BACKEND_URL } from "../../utils/constant";
import { formatDateToYYYYMMDD } from "../../utils/common";
import { Pagination } from "../../components/Pagination";
import * as XLSX from "xlsx";
import useReport from "../../hooks/useReport";

const BankWithdrawalTransactionReport = () => {
  const { generateAndDownloadExcel } = useReport();

  const handleExportClick = (fileName) => {
    generateAndDownloadExcel(data.results.data, fileName);
  };

  const today = new Date();
  const oneMonthAgo = new Date(today);
  oneMonthAgo.setMonth(today.getMonth() - 1);

  const formatMessage = useFormatMessage();
  const itemsPerPage = 30;
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const [currentItems, setCurrentItems] = useState();
  const [startDate, setStartDate] = useState(formatDateToYYYYMMDD(oneMonthAgo));
  const [endDate, setEndDate] = useState(formatDateToYYYYMMDD(today));
  const [data, setData] = useState({
    page: 1,
    limit: itemsPerPage,
    results: null,
    totalCount: null,
  });

  useEffect(() => {
    let url =
      BACKEND_URL +
      `/reports/bankwithdrawltransactionreport?startDate=${startDate}&endDate=${endDate}`;
    axios
      .get(`${url}`, {
        withCredentials: true,
      })
      .then((response) => {
        console.log("Response - ", response.data);
        setData({
          ...data,
          results: response.data,
          totalCount: response.data.data.length,
        });
      })
      .catch((e) => {
        console.log(e.response);
      });
  }, [startDate, endDate]);

  useEffect(() => {
    setCurrentItems(data.results?.data?.slice(startIndex, endIndex));
  }, [data, currentPage]);

  useEffect(() => {
    setData({ ...data, page: currentPage });
  }, [currentPage]);

  return (
    <div className="sub-page-layout-1">
      <Link className="back-to-page" to="/dashboard/report">
        <img src={backArrowIcon} />
        {formatMessage("common.back")}
      </Link>

      <div className="flex justify-between text-3xl">
        <h1>{formatMessage("common.bankWithdrawalTransactionReport")}</h1>
        <div className="flex">
          <input
            required
            name="start_date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="x-input mr-3"
            type="date"
          />
          <input
            required
            name="end_date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="x-input mr-4"
            type="date"
          />
          <button
            className="header-img-button"
            onClick={() => {
              handleExportClick(
                `Bank_Withdrawal_Transaction_Report_from${startDate}_to_${endDate}.xlsx`
              );
            }}
          >
            <img src={printIcon} />
          </button>
        </div>
      </div>
      <div className="table-container">
        <table className="x-table" id="xlsx-export">
          <thead>
            <tr
              style={{
                position: "absolute",
                width: "0",
                height: "0",
                padding: "0",
                border: "0",
              }}
            >
              <th className="hidden" colSpan={3}></th>
              <th className="hidden">{formatMessage("common.from")}</th>
              <th className="hidden">{startDate}</th>
              <th className="hidden">{formatMessage("common.to")}</th>
              <th className="hidden">{endDate}</th>
            </tr>
            <tr
              style={{
                position: "absolute",
                width: "0",
                height: "0",
                padding: "0",
                border: "0",
              }}
            >
              <th className="hidden" colSpan={7}></th>
            </tr>
            <tr>
              <th>{formatMessage("common.no")}</th>
              <th>{formatMessage("common.transactionDate")}</th>
              <th>{formatMessage("common.bank")}</th>
              <th>{formatMessage("common.accountNumber")}</th>
              <th>{formatMessage("common.transactionType")}</th>
              <th>{formatMessage("common.mmkAmount")}</th>
              <th>{formatMessage("common.cnyAmount")}</th>
            </tr>
          </thead>
          <tbody>
            {currentItems?.map((item, i) => {
              return (
                <tr key={i} className="hover-effect">
                  <td>{i + 1}</td>
                  <td>{item.transaction_date}</td>
                  <td>{item.bank}</td>
                  <td>{item.account_number}</td>
                  <td>{item.transaction_type}</td>
                  <td>{item.mmk_amount}</td>
                  <td>{item.cny_amount}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination-container">
        <Pagination data={data} setCurrentPage={setCurrentPage} />
      </div>
    </div>
  );
};

export default BankWithdrawalTransactionReport;

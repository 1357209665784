import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useFormatMessage } from "../../hooks/useFormatMessage";
import backArrowIcon from "../../img/back-arrow.png";
import printIcon from "../../img/icon-print.png";
import { BACKEND_URL } from "../../utils/constant";
import { formatDateToYYYYMMDD } from "../../utils/common";
import { Pagination } from "../../components/Pagination";
import useReport from "../../hooks/useReport";

const SaleOrderReport = () => {
  const { generateAndDownloadExcel } = useReport();
  const handleExportClick = (fileName) => {
    generateAndDownloadExcel(data.results.data, fileName);
  };
  const today = new Date();
  const oneMonthAgo = new Date(today);
  oneMonthAgo.setMonth(today.getMonth() - 1);

  const formatMessage = useFormatMessage();
  const itemsPerPage = 30;
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const [currentItems, setCurrentItems] = useState();
  const [startDate, setStartDate] = useState(formatDateToYYYYMMDD(oneMonthAgo));
  const [endDate, setEndDate] = useState(formatDateToYYYYMMDD(today));
  const [data, setData] = useState({
    page: 1,
    limit: itemsPerPage,
    results: null,
    totalCount: null,
  });

  useEffect(() => {
    let url =
      BACKEND_URL +
      `/reports/saleordereport?startDate=${startDate}&endDate=${endDate}`;
    axios
      .get(`${url}`, {
        withCredentials: true,
      })
      .then((response) => {
        console.log("Response - ", response.data);
        setData({
          ...data,
          results: response.data,
          totalCount: response.data.data.length,
        });
      })
      .catch((e) => {
        console.log(e.response);
      });
  }, [startDate, endDate]);

  useEffect(() => {
    setCurrentItems(data.results?.data?.slice(startIndex, endIndex));
  }, [data, currentPage]);

  useEffect(() => {
    setData({ ...data, page: currentPage });
  }, [currentPage]);

  return (
    <div className="sub-page-layout-1">
      <Link className="back-to-page" to="/dashboard/report">
        <img src={backArrowIcon} />
        {formatMessage("common.back")}
      </Link>

      <div className="flex justify-between text-3xl">
        <h1>{formatMessage("common.saleOrderReport")}</h1>
        <div className="flex">
          <input
            required
            name="start_date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="x-input mr-3"
            type="date"
          />
          <input
            required
            name="end_date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="x-input mr-4"
            type="date"
          />
          <button
            className="header-img-button"
            onClick={() => {
              handleExportClick(
                `Sale_Order_Report_from_${startDate}_to_${endDate}.xlsx`
              );
            }}
          >
            <img src={printIcon} />
          </button>
        </div>
      </div>

      <div className="table-container">
        <table className="x-table" id="xlsx-export">
          <thead>
            <tr
              style={{
                position: "absolute",
                width: "0",
                height: "0",
                padding: "0",
                border: "0",
              }}
            >
              <th className="hidden" colSpan={5}></th>
              <th className="hidden">{formatMessage("common.from")}</th>
              <th className="hidden">{startDate}</th>
              <th className="hidden">{formatMessage("common.to")}</th>
              <th className="hidden">{endDate}</th>
            </tr>
            <tr
              style={{
                position: "absolute",
                width: "0",
                height: "0",
                padding: "0",
                border: "0",
              }}
            >
              <th className="hidden" colSpan={9}></th>
            </tr>
            <tr>
              <th>{formatMessage("common.no")}</th>
              <th>{formatMessage("common.saleId")}</th>
              <th>{formatMessage("common.invoiceId")}</th>
              <th>{formatMessage("common.date")}</th>
              <th>{formatMessage("common.merchantName")}</th>
              <th>{formatMessage("common.totalAmount")}</th>
              <th>{formatMessage("common.paidAmount")}</th>
              <th>{formatMessage("common.balance")}</th>
              <th>{formatMessage("common.dueDate")}</th>
            </tr>
          </thead>
          <tbody>
            {currentItems?.map((item, i) => {
              return (
                <tr key={i} className="hover-effect">
                  <td>{i + 1}</td>
                  <td>{item["Sale Order ID"]}</td>
                  <td>{item["Invoice ID"]}</td>
                  <td>{item["Date"]}</td>
                  <td>{item["Merchant Name"]}</td>
                  <td>
                    {parseInt(item["Total Amount"]).toLocaleString("en-US")}
                  </td>
                  <td>
                    {parseInt(item["Paid Amount"]).toLocaleString("en-US")}
                  </td>
                  <td>{parseInt(item["Balance"]).toLocaleString("en-US")}</td>
                  <td>{item["Due Date"]}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination-container">
        <Pagination data={data} setCurrentPage={setCurrentPage} />
      </div>
    </div>
  );
};

export default SaleOrderReport;
